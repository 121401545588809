/* You can add global styles to this file, and also import other style files */
@import "primeicons/primeicons.css";
@import "primeng/resources/primeng.css";

:root {
    /* COLORS */

    /* Primary - Blue */
    --clr-primary: #326bee;
    --clr-primary-light1: #326bee0d;
    --clr-primary-light2: #326bee08;
    --clr-primary-light3: #326bee1a;

    /* Primary Accent - Blue */
    --clr-primary-accent: #D2DFFF;
    --clr-primary-accent1: #F3F7FF;
    --clr-primary-accent2: #6d96ff;
    --clr-primary-accent3: #f0f5ff;
    --clr-primary-accent4: #f3f6fe;
    --clr-primary-accent5: #326BEE12;

    /* Secondary - Grey*/
    --clr-black: #000000;
    --clr-secondary-dark: #888888;
    --clr-secondary: #666666;
    --clr-secondary-light1: #66666699;
    --clr-secondary-light2: #66666666;
    --clr-secondary-light3: #6666660d;

    /* Light Background */
    --clr-white: #ffffff;
    --clr-light-gray1: #f8f8f8;
    --clr-light-gray2: #F5F5F5;
    --clr-light-gray3: #e8e8e8;
    --clr-light-gray4: #d9d9d9;

    /* Borders */
    --clr-border1: #dfe3e7;
    --clr-border2: #cccccc;

    --clr-overlay: #66666680;

    /* Other Colors */
    --clr-orange-text: #f58020;
    --clr-orange-bg: #FFAD3A1A;
    --clr-red-star: #e24a47;

    --clr-red: #ff7878;
    --clr-red-bg: #ffbfbf;
    --clr-red-bg-light: #ffbfbfb2;
    --clr-green: #008000b2;
    --clr-green-bg: #b8e9b8;
    --clr-green-bg-light: #b8e9b8b2;
    --clr-violet: #6355b9;
    --clr-violet-bg: #abb3ff;
    --clr-violet-bg-light: #abb3ffb2;
    --clr-blue: #1282be;
    --clr-blue-bg: #a3dfff;
    --clr-blue-bg-light: #a3dfffb2;
    --clr-grey: #7a8592;
    --clr-grey-bg: #e5e6eb;
    --clr-success-green: #55BA45;
    --clr-failure-red: #F73C4F;

    /* STATUS COLOR */
    --clr-enabled: #659C4C;
    --clr-enabled-bg: #E3F5DA;
    --clr-disabled: #E24A47;
    --clr-disabled-bg: #FDEBEB;
    --clr-inactive: #666666;
    --clr-inactive-bg: #66666614;
    --clr-active: #A59F19;
    --clr-active-bg: #F9F6BC;
    --clr-invited: #A59F19;
    --clr-invited-bg: #F9F6BC;
    --clr-joined: #9080BC;
    --clr-joined-bg: #EAE8FE;
    --clr-published: #B18C3D;
    --clr-published-bg: #FEE6AA;


    --fc-dark: var(--clr-black);
    --fc-white: var(--clr-white);
    --fc-light-dark: var(--clr-secondary);
    --fc-primary: var(--clr-primary);

    /* FONT SIZES */
    --fs-small: 0.325rem;
    --fs-small: clamp(0.325rem, 0.265rem + 0.3vw, 0.625rem);

    --fs-medium: 0.55rem;
    --fs-medium: clamp(0.55rem, 0.51rem + 0.19999999999999996vw, 0.75rem);

    --fs-large: 0.675rem;
    --fs-large: clamp(0.675rem, 0.635rem + 0.19999999999999996vw, 0.875rem);

    --fs-heading1: 0.8rem;
    --fs-heading1: clamp(0.8rem, 0.76rem + 0.19999999999999996vw, 1rem);

    --fs-heading2: 0.925rem;
    --fs-heading2: clamp(0.925rem, 0.885rem + 0.19999999999999996vw, 1.125rem);

    --fs-heading3: 1.05rem;
    --fs-heading3: clamp(1.05rem, 1.01rem + 0.19999999999999996vw, 1.25rem);

    --fs-heading4: 1.3rem;
    --fs-heading4: clamp(1.3rem, 1.26rem + 0.19999999999999996vw, 1.5rem);

    /* FONT WEIGHTS */ 
    --fw-regular: 400;
    --fw-semi-bold: 500;
    --fw-bold: 600;
}

* {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
}

html::-webkit-scrollbar {
    display: none;
}

html {
    height: 100%;
}

body {
    background: var(--white);
    line-height: 1.4;
    overflow: auto;
    position: relative !important;
}

input,
textarea,
I button,
select,
a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:focus,
a:visited,
a:active {
    outline: none;
}

body::-webkit-scrollbar {
    width: 0px;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    height: 100%;
    background: var(--white);
    font-style: normal;
    font-weight: var(--fw-regular);
    font-size: var(--fs-medium);
    overflow: hidden;
}

p {
    margin-block-start: auto;
    margin-block-end: auto;
}

img,
video {
    max-width: 100%;
}

input {
    max-width: 25vw !important;
    height: 2.5rem !important;
    padding: 1% !important;
    padding-left: 12px !important;
    border: 1px solid var(--clr-secondary-light2);
    border-radius: 5px;
    font-family: inherit;
    font-weight: var(--fw-semi-bold);
    margin: 1vh 0;
}

input::placeholder {
    font-family: inherit;
    color: var(--clr-secondary-light2);
    font-size: var(--fs-medium);
}

*:focus {
    outline: none;
}

.p-button-text {
    font-size: var(--fs-large) !important;
    color: var(--clr-secondary) !important;
}

.p-button-text:hover {
    color: var(--clr-black) !important;
}

.p-button-secondary {
    border: none;
    background: none !important;
    cursor: pointer;
}

.simple-btn {
    padding: 3px 3px;
    background: none;
    color: var(--fc-dark);
    border: none;
}

.p-icon {
    width: 0.8rem !important;
    height: 0.8rem !important;
}

/* PRIME-NG DROPDOWN */
.p-dropdown {
    width: 25vw;
    height: 38px;
    margin: 1vh 0 1vh 0;
    border: 1px solid var(--clr-border1);
    border-radius: 5px;
    align-items: center;
}

.p-dropdown .p-dropdown-trigger>.pi-chevron-down {
    font-size: var(--fs-small);
}

.p-dropdown-panel .p-dropdown-items {
    padding: 0 !important;
}

.userForm-elements>.p-element>.p-dropdown,
.templateForm-elements>.p-element>.p-dropdown {
    width: 30vw;
}

.templateForm-elements.invite-dropdown>.p-element>.p-dropdown {
    width: 25vw !important;
}

.emailForm-elements .p-inputwrapper .p-dropdown,
.offerForm-elements .p-inputwrapper .p-dropdown {
    width: 25vw !important;
}

.p-dropdown-items-wrapper .p-dropdown-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: var(--fs-medium);
}

.p-dropdown-items-wrapper .p-dropdown-items .p-element {
    width: 100%;
}

.right-cont p {
    font-weight: 400;
    font-style: normal !important;
    font-size: var(--fs-medium) !important;
}

.p-dropdown .p-dropdown-label {
    font-size: var(--fs-medium);
}

.p-dropdown.p-focus {
    box-shadow: none !important;
}

.p-dropdown .p-dropdown-trigger {
    width: 36px !important;
}

.p-divider.p-divider-vertical {
    margin: 0 1rem;
    padding: 1.25rem 0;
}

.p-divider.p-divider-vertical:before {
    border-left: 1px #6666664D;
    top: inherit;
    height: 40%;
}

.p-divider.p-divider-vertical .p-divider-content {
    padding: 0.5rem 0;
}

.p-divider.p-divider-horizontal {
    margin: 0 1rem;
    padding: 1.25rem 0;
}

.p-divider.p-divider-horizontal:before {
    border-left: 1px #6666664D;
    top: inherit;
    height: 40%;
}

.p-divider.p-divider-horizontal .p-divider-content {
    padding: 0.5rem 0;
}

/* PRIME-NG FLOATLABEL */
.p-float-label>label {
    left: 0.75rem;
    color: var(--fc-light-dark);
    transition-duration: 0.2s;
    font-size: var(--fs-medium);
    font-weight: var(--fw-semi-bold);
}

.p-float-label input.p-filled~label,
.p-float-label input:focus~label,
.p-float-label .p-inputwrapper-focus~label,
.p-float-label .p-inputwrapper-filled~label {
    top: 1vh !important;
    color: var(--fc-primary) !important;
    background-color: var(--clr-white);
    font-size: 11px !important;
    font-weight: var(--fw-semi-bold);
    padding: 3px;
}

.p-float-label>.p-dropdown {
    width: 12rem !important;
}

.p-float-label>.p-chip{
    width: 12rem !important;
}

.leftcont .p-dialog {
    font-size: var(--fs-medium);
    position: absolute;
    top: 7%;
    right: 1%;
}

.leftcont .p-dialog-header {
    display: none !important;
}

.leftcont .p-dialog-header-icons {
    display: none !important
}

.leftcont .p-dialog .p-dialog-content {
    padding: 0.5rem !important;
    border-radius: 5px;
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.2);
}

.notification .p-dialog {
    font-size: var(--fs-medium);
    position: absolute;
    top: 7%;
    right: 1%;
}

.notification .p-dialog-header {
    display: none !important;
}

.notification .p-dialog-header-icons {
    display: none !important
}

.notification .p-dialog .p-dialog-content {
    padding: 0.5rem !important;
    border-radius: 5px;
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.2);
}

.ng2-pdf-viewer-container{
    height: 90% !important  ;
}

.p-datatable .p-datatable-wrapper {
    position: relative;
    overflow: auto;
}

.p-datatable-wrapper>.p-datatable-table>.p-datatable-thead {
    position: sticky;
    top: 0;
    z-index: 1;
}

.p-datatable .p-datatable-thead,
.p-datatable .p-datatable-tbody {
    display: block;
}

.p-datatable .p-datatable-thead>tr,
.p-datatable .p-datatable-tbody>tr {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    background: #ffffff;
    color: #000000;
    transition: all 0.1s;
}

.p-datatable .p-datatable-thead>tr>th {
    padding: 0.5rem 1rem !important;
}

::ng-deep .p-datatable-tbody >.error-row {
    color: red !important;
}

th:not(.p-sortable-column):hover {
    background: none;
}

.p-datatable-thead tr {
    background-color: var(--clr-primary-accent) !important;
}

.p-datatable-thead {
    background-color: unset !important;
}

.p-datatable .p-sortable-column:focus {
    box-shadow: unset !important;
}

.p-datatable .p-sortable-column:hover {
    background: none !important;
}

.p-datatable .p-datatable-header {
    padding: 0vh 1vw !important;
}

.p-overlaypanel::before,
.p-overlaypanel::after {
    content: unset !important;
}

.p-overlaypanel .p-overlaypanel-content {
    padding: unset !important;
}

.p-overlaypanel {
    position: absolute !important;
    border-radius: 0px 0px 5px 5px;
    z-index: 1001 !important;
}

.table-relative {
    position: relative !important;
}

.p-checkbox {
    width: 1rem !important;
    height: 1rem !important;
    border: var(--clr-secondary) !important;
    margin: 3px;
    align-items: flex-start;
}

.p-checkbox .p-checkbox-box {
    width: inherit !important;
    height: inherit !important;
    border-radius: 3px !important;
    color: var(--clr-secondary) !important;
}

button:focus,
button:active,
.p-checkbox-box.p-focus {
    box-shadow: unset !important;
    outline: unset !important;
}

.p-tooltip {
    min-width: 0px !important;
    max-width: 500px !important;
    font-size: var(--fs-medium) !important;
}

.p-accordion {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.p-accordion-tab {
    margin-bottom: 0 !important;
}

.p-accordion-header {
    position: relative;
    font-size: var(--fs-medium) !important;
}

.p-accordion .p-accordion-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    border: 1px solid var(--clr-primary-accent) !important;
    box-shadow: unset !important;
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link {
    border: 1px solid var(--clr-primary-accent) !important;
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    background: var(--clr-primary-light2) !important;
}

.create-body1 .p-accordion .p-accordion-content {
    border: unset !important;
    padding: 0.5rem !important;
}

.p-radiobutton .p-radiobutton-box {
    border: 1px solid #666666;
    background: #ffffff;
    /* width: 16px !important; */
    /* height: 16px !important; */
    color: #495057;
    border-radius: 50%;
    margin-top: 2px;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: #326BEE;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfdbfe;
    border-color: #326BEE;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    width: 11px !important;
}

.loadingOverlay {
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 15000;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.4);
}

.p-button-create {
    min-width: 142px;
    height: 2.5rem;
    border-radius: 5px;
    font-size: var(--fs-medium);
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.blue-outlined-btn {
    color: var(--clr-primary);
    background-color: transparent;
    border: 1.5px solid var(--clr-primary);
}

.blue-filled-btn {
    color: var(--clr-white);
    background-color: var(--clr-primary);
    border: none;
}

body .header-cont {
    width: 100%;
    /* margin-top: 10px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between !important;
    padding: 0.2rem 1.25rem 0.2rem 1.25rem;
}

body .header-cont span {
    color: var(--clr-black);
    font-size: var(--fs-heading2);
    font-weight: var(--fw-bold);
}

.header-cont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-cont span {
    font-size: var(--fs-heading2);
    font-weight: var(--fw-bold);
    color: var(--clr-black);
}

.p-float-label>.ng-invalid.ng-dirty+label {
    color: unset !important;
}

body .rightcont .p-sidebar {
    width: 60vw !important;
}

.p-sidebar {
    position: absolute;
    transition: transform .3s;
    display: flex;
    flex-direction: column;
}

.table-cont .p-sidebar-left {
    position: absolute !important;
    width: 24vw !important;
    top: 56px !important;
    height: 72vh;
}

::ng-deep  .button-cont .p-sidebar-header {
    display: flex;
    flex-direction: column;
    height: fit-content;
    align-items: unset !important;
    padding: unset !important;
}

body .headerCont .p-sidebar-header {
    display: flex;
    flex-direction: column;
    height: fit-content;
    padding: 0.5rem 1rem !important;
}

.p-sidebar-right>.p-sidebar-header>.p-sidebar-close {
    top: 0;
    position: absolute;
    left: -40px;
    background: var(--clr-secondary) !important;
    color: var(--fc-white) !important;
    height: 40px !important;
    width: 40px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.p-sidebar-left>.p-sidebar-header>.p-sidebar-close {
    height: 20px !important;
    width: 20px !important;
    background: var(--clr-white) !important;
    color: var(--clr-black) !important;
    border-radius: unset !important;
    padding: 5px;
}

.p-sidebar-left .p-sidebar-icon svg {
    width: 0.8rem !important;
    height: 0.8rem !important;
}

::ng-deep .button-cont .p-sidebar .p-sidebar-content {
    margin-top: 1.5rem;
}

.table-cont .p-sidebar > .p-sidebar-header {
    height: 40px;
    padding: 0.5rem 1rem;
    background: var(--clr-primary-accent2);
    color: var(--clr-white);
    border-radius: 0px 0px 5px 5px;
    font-size: var(--fs-medium);
    font-weight: var(--fw-bold);
    font-family: inherit;
    flex-direction: row;
    justify-content: space-between;
}

.table-cont .p-sidebar > .p-sidebar-content {
    padding: 0.5rem !important;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

::ng-deep .table-cont .p-sidebar .p-sidebar-footer {
    padding: unset !important;
}

.p-link {
    border-radius: 6px !important;
}

.p-inputtext:enabled:hover {
    border: 1px solid var(--clr-primary) !important;
    outline: none !important;
}

.p-inputtext:active {
    box-shadow: none;

}

.create-fields .p-inputwrapper .p-component {
    width: 25vw;
    border-radius: 6px;
}

::ng-deep body .headerCont .create-fields input {
    width: 15vw;
    border-radius: 6px;
    gap: 20px;
}

.create-fields input {
    height: 38px;
}

.p-dropdown .p-inputtext {
    padding: 0.5rem 0.7rem !important;
}

.p-input-icon-left>em {
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
    font-size: var(--fs-large);
    color: var(--clr-secondary-light2);
    margin-left: 8px;
}

.p-inputgroup>input::placeholder {
    font-size: var(--fs-small);
    position: relative;
    left: 0.5rem;
}


.sidebar-saveSettings>.p-inputgroup>button>.p-button-label {
    font-size: var(--fs-small);
}

.p-inputtext {
    width: 25vw;
    font-weight: var(--fw-semi-bold);
    color: var(--fc-dark) !important;
    margin: 1vh 0 !important;
    padding-left: 15px !important;
    font-family: unset !important;
}

.p-input-icon-left>.p-inputtext {
    width: 100%;
    height: 38px !important;
    padding-left: 2.5rem;
    border-radius: 4px !important;
    background: var(--clr-light-gray1);
}


.p-inputtext:focus {
    box-shadow: unset !important;
}


.p-chips .p-chips-multiple-container:not(p-disabled).p-focus {
    box-shadow: unset !important;
}

.p-input-icon-left {
    color: var(--clr-secondary-light2) !important;
}

.create-fields .input {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol;
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    padding: .75rem;
    border: 1px solid #ced4da;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    appearance: none;
    border-radius: 6px
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: 5px;
    font-size: 12px;
    background: #f8f8f8 !important;
    color: #000000 !important;
    border: 1px solid #dfe3e7 !important;
    margin: 8px 5px 0 !important;
    border-radius: 3px;
    max-width: 24vw;
}

.p-multiselect {
    width: 25vw;
    height: 38px;
    margin: 1vh 0;
}

.profile-header .p-multiselect {
    margin: unset !important
}


.profile-header .p-multiselect .p-multiselect-label , .field-multiselect .p-multiselect .p-multiselect-label {
    padding: 0.5rem 0.5rem !important;
    white-space: nowrap !important;
    text-overflow: ellipsis;
}

.field-multiselect .p-multiselect {
    margin: unset !important
}


.field-multiselect .p-multiselect .p-multiselect-label {
    padding: 0.5rem 0.5rem !important;
}

.p-multiselect-label {
    cursor: pointer;
    overflow: auto;
}

.p-multiselect .p-multiselect-trigger {
    width: 1.5rem !important;
}

.p-multiselect-trigger-icon.pi {
    font-size: 12px;
}

.p-multiselect-filter-container .p-inputtext {
    height: 2rem !important;
}

.p-multiselect-panel .p-multiselect-header {
    padding: 0.25rem 1rem !important;
}

.p-progress-spinner {
    position: relative;
    top: 50%;
    left: 50%;
}

.p-progress-spinner-circle {
    stroke: var(--clr-black) !important;
    opacity: 1 !important;
}

span.imp {
    color: var(--clr-red-star);
}

/*display properties common css*/

.df-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.df-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.drives-sidebar .p-sidebar {
    width: 90vw !important;
}

.drives-sidebar .p-sidebar .p-sidebar-header {
    padding: 1.5rem 1rem !important;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: var(--clr-primary-light2);
}
.drives-sidebar .p-sidebar-footer {
    display: none !important;
}

.drives-sidebar .p-sidebar-content {
    padding: 1rem !important;
    background: var(--clr-secondary-light3);
}

.create-fields input,
.create-fields .p-inputwrapper .p-component {
    width: 25vw;
    border-radius: 6px;
    min-height: 38px !important;
}


.create-fields .chips-form .p-chips ul {
    width: 100% !important;
    min-height: 38px !important;
    overflow: unset !important;
    padding: unset !important;
    padding-left: 10px !important;
    padding-top: 10px !important;
    gap: 0.2rem !important
}

.p-chips .p-chips-multiple-container .p-chips-input-token {
    padding: unset !important;
}

.radio-status {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.profile-dropdowns .p-multiselect .p-multiselect-label {
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    padding: 0.5rem 0.75rem !important;
}

.profile-dropdowns .p-multiselect {
    width: 250px !important;
}

.profile-dropdowns .p-multiselect-label {
    font-size: var(--fs-medium) !important;
}

.profile-dropdowns .p-multiselect-item {
    font-size: var(--fs-medium) !important;
}

.profile-dropdowns .p-multiselect:not(.p-disabled).p-focus , .field-multiselect .p-multiselect:not(.p-disabled).p-focus {
    box-shadow: unset !important;
}

.p-component {
    font-family: "Poppins", sans-serif !important;
    font-style: normal !important;
    font-size: var(--fs-medium) !important;
}

/* PRIME-NG INPLACE */
.p-inplace.p-component.p-inplace-closable {
    width: 30vw;
    height: 3rem;
    display: inline-flex;
    align-items: center;
}

.p-inplace .p-inplace-display {
    display: block !important;
    width: fit-content;
    font-size: var(--fs-small);
    font-weight: var(--fw-semi-bold);
    color: var(--fc-light-dark);
    padding: 0.5rem;
}

.p-inplace .p-inplace-content {
    width: inherit;
}

.p-inplace-content>.p-button.p-button-icon-only {
    height: 2rem;
    width: 2rem;
    border-radius: 0 4px 4px 0;
    margin: auto 0 !important;
}

.p-inplace-content>.ng-star-inserted {
    height: 2rem !important;
    border-radius: 4px 0 0 4px;
    margin: auto 0 !important;
}

.addCategory .p-inplace .p-inplace-display {
    border: var(--clr-primary) 1px solid;
    color: var(--fc-primary);
    width: 100%;
    height: 2.5rem;
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
}

.addCategory>.p-inplace>.p-inplace-content>.newCategory {
    margin: 0.5rem auto !important;
    width: 99%;
}

.header-btn>.p-button-raised {
    background-color: var(--clr-primary) !important;
    color: var(--fc-white) !important;
}

/* PRIME-NG DIALOG */
.placeholder-dialog>.p-dialog-top-right>.p-dialog>.p-dialog-header {
    background: #6666660d;
    height: 50px;
    padding: 10px 20px;
}

.placeholder-dialog>.p-dialog-top-right>.p-dialog>.p-dialog-content {
    padding: 0.5rem 1rem;
}

.placeholder-dialog>.p-dialog-top-right>.p-dialog>.p-dialog-header>span {
    font-size: var(--fs-medium);
}

.placeholder-dialog>.p-dialog-top-right>.p-dialog {
    border-radius: 5px;
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.06);
    background: var(--clr-white);
    border: 1px solid var(--clr-border1);
    padding: 1px;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    border-radius: 2px !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
}

.p-dialog .p-dialog-footer button {
    height: 40px !important;
}

/* PRIME-NG CHIP */

#p1-chips .p-chips .p-chips-multiple-container {
    max-width: 40vw !important;
}

#p1-chips .p-chips .p-chips-multiple-container .p-chips-token {
    margin-right: 0.5rem !important;
    background: #eff6ff !important;
    color: #1d4ed8 !important;
}

.p-chip {
    background-color: var(--clr-white) !important;
    border: 1px solid var(--clr-border2);
    border-radius: 3px !important;
    padding: 0 !important;
    margin: 7px 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.p-chip:hover {
    background-color: var(--clr-secondary-light3) !important;
}

.p-chip .p-chip-text {
    font-size: 12px !important;
    margin: 4px 15px !important;
}

/* PRIME-NG CHIPS */
.p-chips-multiple-container {
    width: 78.5vw;
    min-height: 2.5rem;
    padding-bottom: 8px !important;
}

.p-chips .p-chips-multiple-container .p-chips-token {
    padding: 5px;
    font-size: 12px;
    border: 1px solid #dfe3e7 !important;
    margin: 8px 5px 0 !important;
    border-radius: 3px;
    /* max-width: 24vw; */
    height: 30px !important;
}

.width100 {
    width: 100% !important;
}

/* PRIME-NG PANEL */
.p-panel.p-panel-toggleable .p-panel-header {
    background-color: var(--clr-white);
    font-weight: var(--fw-semi-bold);
    color: var(--fc-dark);
    border-radius: 2px;
    border: 1px solid #dfe3e7;
    height: 3.125rem;
}

.p-panel.p-panel-toggleable .p-panel-header:hover {
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
}

.p-panel .p-panel-header .p-panel-header-icon {
    border-radius: 5px !important;
    margin-left: 8px;
    background-color: var(--clr-secondary-light3) !important;
    color: var(--clr-secondary) !important;
}

.p-panel .p-panel-header .p-panel-header-icon:hover {
    background-color: #66666622 !important;
}

.p-panel-titlebar-toggler {
    background-color: var(--clr-primary) !important;
}

.p-ripple.p-panel-header-icon.p-panel-toggler {
    display: none !important;
}

.flex {
    display: flex !important;
}

.justify-center{
    justify-content: center;
}

.justify-end {
    justify-content: end;
}

.justify-between {
    justify-content: space-between;
}

.cursor {
    cursor: pointer;
}

.flex-column{
    flex-direction: column;
}

.align-center{
    align-items: center;
}

.signin-spinner>.p-progress-spinner {
    top: 0px !important;
    left: 0px !important;
    display: flex;
}

.sign-btn-load>.p-element>.p-progress-spinner>.p-progress-spinner-svg>.p-progress-spinner-circle {
    stroke: var(--clr-white) !important;
}


.p-tieredmenu{
    width: 11.5rem !important;
}

/* p - sidebar */
.p-sidebar-header {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.fileUpload-dropzone>input {
    position: static !important;
}

/* checkbox */

.p-checkbox-content .checkk {
    display: inline-flex;
    vertical-align: bottom;
    align-items: flex-start;
}

.custom-bread-crumb .line {
    padding: 0 8px !important;
}

.custom-bread-crumb li a:hover {
    text-decoration: none !important;
}

.custom-bread-crumb li span {
    font-weight: 600;
}

.custom-bread-crumb {
    /* width        : 48vw; */
    white-space  : nowrap;
    text-overflow: ellipsis;
    overflow-x   : hidden;
}

.custom-bread-crumb:hover {
    overflow-x   : scroll;
    text-overflow: clip;
}

.custom-bread-crumb::-webkit-scrollbar {
    display: none;
}
app-button > button:disabled{
    cursor: not-allowed !important;
    opacity: 0.7;
}
.table-aciton-icons .p-overlaypanel{
    left: 2vh !important;
    top: 5vh !important;
}

.p-sidebar .p-sidebar-footer{
    padding: 0.25rem !important;
}

.gap-5 {
    gap: 5px;
}

.gap-30 {
    gap: 30px;
}

.gap-20 {
    gap: 20px;
}

.gap-10 {
    gap: 10px;
}

.gap-15 {
    gap: 15px;
}
.custom-divider {
    border: 1px solid var(--clr-border1);
}
.dashed {
    border-style: dashed;
}
.dotted {
    border-style: dotted;
}
.text-underline:hover{
    text-decoration: underline;
    cursor: pointer;
}

.templateForm-elements {
    margin: 5px 0;
}

.templateForm-elements>input:hover {
    border-color: var(--clr-primary);
}

.templateForm-elements>input {
    min-width : 32vw !important;
    transition: border-color 0.2s;
}
.templateForm-elements .p-inputwrapper .p-component {
    width: 32vw;
    border-radius: 6px;
}

.p-datepicker-trigger.p-button {
    background  : none !important;
    color       : var(--clr-black) !important;
    border-left : none !important;
    border-color: transparent !important;
}
.p-calendar-w-btn .p-datepicker-trigger {
    height: 40px;
    right : 40px;
}
.p-calendar .p-inputtext{
    width: 30vw !important;
}
.p-calendar-w-btn .p-inputtext {
    border-radius: 6px!important;
}
.p-calendar {
    align-items: center
}
.invalid {
    border-color: #e24c4c!important;
    color: #e24c4c
}
.info-list {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 20px;
    background: var(--clr-white);
    color: var(--clr-secondary);
    border: 1px solid var(--clr-border1);
    cursor: pointer;
    border-radius: 5px;
    height: 38px;
    font-size: var(--fs-medium);
}
.title-error{
    color: #D8000C;
    font-size: 11px;
    position: absolute;
    top: -2px;
}
.gap-0{
    gap: 0px !important;
}

#driveSplitButton .p-tieredmenu.p-tieredmenu-overlay {
    width: 13.5rem !important;
}

#saveTemplate-split-button .p-tieredmenu.p-tieredmenu-overlay {
    width: 13.5rem !important;
}

@media (min-width:641px) {

    *::-webkit-scrollbar {
        width : 6px !important;
        height: 6px !important;
    }

    *::-webkit-scrollbar-track {
        background-color: transparent !important;
    }

    *::-webkit-scrollbar-thumb {
        border-radius   : 10px !important;
        background-color: #D6D6D6 !important;
    }
}

.p-password{
    width: 100%;
}

#password-input-id .p-input-icon-right > .p-inputtext {
    width: inherit;
}

.spinner-size .p-progress-spinner { 
    height: 90px;
    left: 0;
}

.divider {
    width: 100%;
    height: 1px;
    background: var(--clr-border);
}

/* .p-inputtext[type="password"] {
    font-size: 22px !important;
} */

.accordion-disabled .p-disabled, .p-disabled * {
    cursor: default !important;
    pointer-events: auto !important;
}