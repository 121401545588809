:root {
    --surface-a           : #ffffff;
    --surface-b           : #f8f9fa;
    --surface-c           : #e9ecef;
    --surface-d           : #dee2e6;
    --surface-e           : #ffffff;
    --surface-f           : #ffffff;
    --text-color          : #495057;
    --text-color-secondary: #666666;
    --primary-color       : #326BEE;
    --primary-color-text  : #ffffff;
    --font-family         : -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
        Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    --surface-0      : #ffffff;
    --surface-50     : #fafafa;
    --surface-100    : #f5f5f5;
    --surface-200    : #eeeeee;
    --surface-300    : #e0e0e0;
    --surface-400    : #bdbdbd;
    --surface-500    : #9e9e9e;
    --surface-600    : #757575;
    --surface-700    : #616161;
    --surface-800    : #424242;
    --surface-900    : #212121;
    --gray-50        : #fafafa;
    --gray-100       : #f5f5f5;
    --gray-200       : #eeeeee;
    --gray-300       : #e0e0e0;
    --gray-400       : #bdbdbd;
    --gray-500       : #9e9e9e;
    --gray-600       : #757575;
    --gray-700       : #616161;
    --gray-800       : #424242;
    --gray-900       : #212121;
    --content-padding: 1.25rem;
    --inline-spacing : 0.5rem;
    --border-radius  : 6px;
    --surface-ground : #eff3f8;
    --surface-section: #ffffff;
    --surface-card   : #ffffff;
    --surface-overlay: #ffffff;
    --surface-border : #dfe7ef;
    --surface-hover  : #f6f9fc;
    --focus-ring     : 0 0 0 0.2rem #bfdbfe;
    --maskbg         : rgba(0, 0, 0, 0.4);



    --blue-50     : #f5f9ff;
    --blue-100    : #d0e1fd;
    --blue-200    : #abc9fb;
    --blue-300    : #85b2f9;
    --blue-400    : #609af8;
    --blue-500    : #326BEE;
    --blue-600    : #326fd1;
    --blue-700    : #295bac;
    --blue-800    : #204887;
    --blue-900    : #183462;
    --green-50    : #f4fcf7;
    --green-100   : #caf1d8;
    --green-200   : #a0e6ba;
    --green-300   : #76db9b;
    --green-400   : #4cd07d;
    --green-500   : #22c55e;
    --green-600   : #1da750;
    --green-700   : #188a42;
    --green-800   : #136c34;
    --green-900   : #0e4f26;
    --yellow-50   : #fefbf3;
    --yellow-100  : #faedc4;
    --yellow-200  : #f6de95;
    --yellow-300  : #f2d066;
    --yellow-400  : #eec137;
    --yellow-500  : #eab308;
    --yellow-600  : #c79807;
    --yellow-700  : #a47d06;
    --yellow-800  : #816204;
    --yellow-900  : #5e4803;
    --cyan-50     : #f3fbfd;
    --cyan-100    : #c3edf5;
    --cyan-200    : #94e0ed;
    --cyan-300    : #65d2e4;
    --cyan-400    : #35c4dc;
    --cyan-500    : #06b6d4;
    --cyan-600    : #059bb4;
    --cyan-700    : #047f94;
    --cyan-800    : #036475;
    --cyan-900    : #024955;
    --pink-50     : #fef6fa;
    --pink-100    : #fad3e7;
    --pink-200    : #f7b0d3;
    --pink-300    : #f38ec0;
    --pink-400    : #f06bac;
    --pink-500    : #ec4899;
    --pink-600    : #c93d82;
    --pink-700    : #a5326b;
    --pink-800    : #822854;
    --pink-900    : #5e1d3d;
    --indigo-50   : #f7f7fe;
    --indigo-100  : #dadafc;
    --indigo-200  : #bcbdf9;
    --indigo-300  : #9ea0f6;
    --indigo-400  : #8183f4;
    --indigo-500  : #6366f1;
    --indigo-600  : #5457cd;
    --indigo-700  : #4547a9;
    --indigo-800  : #363885;
    --indigo-900  : #282960;
    --teal-50     : #f3fbfb;
    --teal-100    : #c7eeea;
    --teal-200    : #9ae0d9;
    --teal-300    : #6dd3c8;
    --teal-400    : #41c5b7;
    --teal-500    : #14b8a6;
    --teal-600    : #119c8d;
    --teal-700    : #0e8174;
    --teal-800    : #0b655b;
    --teal-900    : #084a42;
    --orange-50   : #fff8f3;
    --orange-100  : #feddc7;
    --orange-200  : #fcc39b;
    --orange-300  : #fba86f;
    --orange-400  : #fa8e42;
    --orange-500  : #f97316;
    --orange-600  : #d46213;
    --orange-700  : #ae510f;
    --orange-800  : #893f0c;
    --orange-900  : #642e09;
    --bluegray-50 : #f7f8f9;
    --bluegray-100: #dadee3;
    --bluegray-200: #bcc3cd;
    --bluegray-300: #9fa9b7;
    --bluegray-400: #818ea1;
    --bluegray-500: #64748b;
    --bluegray-600: #556376;
    --bluegray-700: #465161;
    --bluegray-800: #37404c;
    --bluegray-900: #282e38;
    --purple-50   : #fbf7ff;
    --purple-100  : #ead6fd;
    --purple-200  : #dab6fc;
    --purple-300  : #c996fa;
    --purple-400  : #b975f9;
    --purple-500  : #a855f7;
    --purple-600  : #8f48d2;
    --purple-700  : #763cad;
    --purple-800  : #5c2f88;
    --purple-900  : #432263;
    --red-50      : #fff5f5;
    --red-100     : #ffd0ce;
    --red-200     : #ffaca7;
    --red-300     : #ff8780;
    --red-400     : #ff6259;
    --red-500     : #ff3d32;
    --red-600     : #d9342b;
    --red-700     : #b32b23;
    --red-800     : #8c221c;
    --red-900     : #661814;
    --primary-50  : #f5f9ff;
    --primary-100 : #d0e1fd;
    --primary-200 : #abc9fb;
    --primary-300 : #85b2f9;
    --primary-400 : #609af8;
    --primary-500 : #326BEE;
    --primary-600 : #326fd1;
    --primary-700 : #295bac;
    --primary-800 : #204887;
    --primary-900 : #183462;
}

* {
    box-sizing: border-box;
}

.p-panelmenu .p-panelmenu-header > a{
    padding: 1rem !important;
    border: 1px solid #dee2e6;
    color: #000000 !important;
    background: var(--clr-primary-light1) !important;
    font-weight: 700;
    border-radius: 6px;
    transition: box-shadow 0.2s;
}

.accordion-disabled  .p-disabled, .p-component:disabled {
    opacity: 1;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link{
    background: #f8f9fa;
    border-color: #dee2e6;
    color: #000000 !important;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.p-accordion .p-accordion-header .p-accordion-header-link{
    padding: 1rem !important;
    border: 1px solid #dee2e6;
    color: #000000 !important;
    background: #f8f9fa;
    font-weight: 700;
    border-radius: 6px;
    transition: box-shadow 0.2s;
}

.grouping-select-dialog .p-dialog-content {
    overflow: revert;
}

.grouping-select-dropdown .p-dropdown-items-wrapper{
    max-height: 100px !important;
}

.p-dialog .p-dialog-footer button {
    gap: 10px;
}

.drive-editor-container .p-editor-container .p-editor-toolbar {
    display: none;
}